import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Hotel } from '../Models/Hotel';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  url = "http://194.233.66.229:8082/";
  // url = "http://localhost:8080/";
  public jwtToken = null;
  public username: string;
  public sendMail = false;
  isAuth = false;
  roles: Array<any>;
  public connected_user;


  constructor(private http: HttpClient) { }


  login(user){
    return this.http.post(this.url + 'login', user, {observe: 'response'});
  }
   getUser(id){
     let headers = new HttpHeaders({'authorization':'Bearer '+ this.jwtToken});
     return this.http.get(this.url+'api/userDetails/'+id,  {headers: headers});
  }



  addUtilisateurs(utilisateur) {
    let headers = new HttpHeaders({'authorization':'Bearer '+ this.jwtToken});
    return this.http.post(this.url+'api/utilisateur',utilisateur,{headers: headers});
  }
  getUtilisateurs(){
    let headers = new HttpHeaders({'authorization':'Bearer '+ this.jwtToken});
    return this.http.get(this.url+'api/utilisateurs/',  {headers: headers});
  }

  getOneUtilisateur(id:number){
    let headers = new HttpHeaders({'authorization':'Bearer '+ this.jwtToken});
    return this.http.get(this.url+'api/utilisateurs/'+id,  {headers: headers});
  }


  addHotel(hotel) {
    let headers = new HttpHeaders({'authorization':'Bearer '+ this.jwtToken});
    return this.http.post(this.url+'api/hotel',hotel,{headers: headers});
  }
  getHotels(){
    let headers = new HttpHeaders({'authorization':'Bearer '+ this.jwtToken});
    return this.http.get(this.url+'api/hotels/',  {headers: headers});
  }

  getOneHotel(id:number){
    let headers = new HttpHeaders({'authorization':'Bearer '+ this.jwtToken});
    return this.http.get(this.url+'api/hotel/'+id,  {headers: headers});
  }
  public updateHotel(hotel:Hotel){
    let headers = new HttpHeaders({'authorization':'Bearer '+ this.jwtToken});
    return this.http.put(this.url+"api/hotel/"+hotel.id,hotel,{headers: headers});
  }
  public deleteHotel(id:number){
    let headers = new HttpHeaders({'authorization':'Bearer '+ this.jwtToken});
    return this.http.delete(this.url+"api/hotel/"+id,{headers: headers});
  }



  saveToken(jwt: string){
    this.jwtToken = jwt;
    localStorage.setItem('token', jwt);
    this.parseJWT();
  }
  parseJWT(){
    const jwtHelpers = new JwtHelperService();
    let objJWT = jwtHelpers.decodeToken(this.jwtToken);
    this.roles = objJWT.roles;
    this.connected_user = objJWT.user_id;
    // console.log(this.jwtToken);
    // console.log("Les Roles",this.roles);
    // console.log("connected user id: ", objJWT.user_id);
  }
  loadToken(){
    this.jwtToken = localStorage.getItem('token');
  }

  logout() {
    this.jwtToken = null;
    localStorage.removeItem('token');
    this.initParams();
    this.isAuth = false;
  }
  initParams(){
    this.jwtToken = undefined;
    this.username = undefined;
    this.roles = undefined;
  }

  isAdmin(){
    return this.roles.indexOf('ADMIN') >= 0;
  }

  isUser(){
    return this.roles.indexOf('USER') >= 0;
  }
  // addInsurance(insurance) {
  //   let headers = new HttpHeaders({'authorization':'Bearer '+ this.jwtToken});
  //   return this.http.post(this.url + 'api/insurance', insurance, {headers: headers});
  // }

  register(user) {
    return this.http.post(this.url + 'api/register', user, {observe: 'response'});
  }
}
