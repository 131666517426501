import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-reservations',
  templateUrl: './add-reservations.component.html',
  styleUrls: ['./add-reservations.component.css']
})
export class AddReservationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
