import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-add-hotels',
  templateUrl: './add-hotels.component.html',
  styleUrls: ['./add-hotels.component.css']
})
export class AddHotelsComponent implements OnInit {

  constructor(private authService: AuthService,private toastr: ToastrService,private router: Router) { }

  ngOnInit(): void {
    this.authService.loadToken();
    this.authService.parseJWT();
  }
  onHotel(f) {
    const hotel = {
      hotelName: f.value['hotelName'],
      nbrePlace: f.value['nbrePlace'],
      adresse: f.value['adresse'],
      // appUser: {
      //   id: this.authService.connected_user
      // }
    };
    this.authService.addHotel(hotel).subscribe(resp => {
      // console.log(resp);
      this.toastr.success("l'hotel a été ajouter avec succès.");
      this.router.navigateByUrl("/hotels");
    }, error => {
      console.log(error);
    });
  }


}
