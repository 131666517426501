import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Hotel } from 'src/app/Models/Hotel';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-add-utilisateurs',
  templateUrl: './add-utilisateurs.component.html',
  styleUrls: ['./add-utilisateurs.component.css']
})
export class AddUtilisateursComponent implements OnInit {
  hotel:Hotel[];
  selectedLevel;
  constructor(private authService: AuthService,private toastr: ToastrService,private router: Router) { }

  ngOnInit(): void {
    this.authService.loadToken();
    this.authService.parseJWT();
    this.authService.getHotels().subscribe(
      (data:any)=>{
        this.hotel =data;
      }
    );
  }
  selected(){
    // console.log(this.selectedLevel)
  }
  onUtilisateur(f) {
    const utilisateur = {
      firstname:f.value['firstname'],
      lastname:f.value['lastname'],
      phone:f.value['phone'],
      email:f.value['email'],
      address:f.value['address'],
      nbreAccompagnateur:f.value['nbreAccompagnateur'],

      appUser: {
        id: this.authService.connected_user
      },
      hotel:{
        id: f.value['hotel']['id']
      }
    };
    // console.log(utilisateur);
    this.authService.addUtilisateurs(utilisateur).subscribe(resp => {
      // console.log(">>>>>",utilisateur);
      // console.log(resp);
      this.toastr.success("Inscription soumise");
      this.router.navigateByUrl("/utilisateurs");
    }, error => {
      this.toastr.warning("Un compte existe déjà pour cette adresse email");

      console.log(error);
    });
  }
}
