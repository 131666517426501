import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { ClientsComponent } from './pages/clients/clients.component';
import { AddUtilisateursComponent } from './pages/add-utilisateurs/add-utilisateurs.component';
import { UpdateUtilisateursComponent } from './pages/update-utilisateurs/update-utilisateurs.component';
import { AddHotelsComponent } from './pages/add-hotels/add-hotels.component';
import { UpdateHotelsComponent } from './pages/update-hotels/update-hotels.component';
import { AddReservationsComponent } from './pages/add-reservations/add-reservations.component';
import { UpdateReservationsComponent } from './pages/update-reservations/update-reservations.component';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { UtilisateursComponent } from './pages/utilisateurs/utilisateurs.component';
import { HotelsComponent } from './pages/hotels/hotels.component';
import { ToastrModule } from 'ngx-toastr';
import { EditHotelComponent } from './edit-hotel/edit-hotel.component';
import { ViewUtilisateurComponent } from './view-utilisateur/view-utilisateur.component';
// import { MatSliderModule } from '@angular/material/slider';


@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    BrowserModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot(),

  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    ClientsComponent,
    UtilisateursComponent,
    HotelsComponent,
    AddUtilisateursComponent,
    UpdateUtilisateursComponent,
    AddHotelsComponent,
    UpdateHotelsComponent,
    AddReservationsComponent,
    UpdateReservationsComponent,
    EditHotelComponent,
    ViewUtilisateurComponent,

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
