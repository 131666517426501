import { Hotel } from "./Hotel";

export class Utilisateur{
  id:number;
  firstname:string;
  lastname:string;
  email:string;
  phone:string;
  sex:string;
  address:string;
  nbreAccompagnateur:number;
  hotel:Hotel;
}
