import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Utilisateur } from 'src/app/Models/Utilisateurs';
import { AuthService } from 'src/app/services/auth.service';
import { UtilisateursService } from 'src/app/services/utilisateurs.service';

@Component({
  selector: 'app-utilisateurs',
  templateUrl: './utilisateurs.component.html',
  styleUrls: ['./utilisateurs.component.css']
})
export class UtilisateursComponent implements OnInit {
  utilisateur:Utilisateur[];
  utilHotel;
  constructor(private authService: AuthService,private router:Router,private utilisateurService :UtilisateursService) { }

  ngOnInit(): void {
    this.authService.loadToken();
    this.authService.parseJWT();
    this.authService.getUtilisateurs().subscribe(
      (data:any)=>{
        this.utilisateur =data;
        // this.utilHotel=this.utilisateur.hotel['hotelName'];

        // console.log("les utilisateurs",this.utilisateur);
      }
    );
  }

  onNewCat() {
    this.router.navigateByUrl('/addUtilisateur');

  }
  onViewUtilisateur(id:number){
    // console.log("iddd=",id);
    this.router.navigate(['viewUtilisateur',id]);
  }

  isAdmin(){
    return this.authService.isAdmin();
  }
}
