import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Hotel } from 'src/app/Models/Hotel';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-hotels',
  templateUrl: './hotels.component.html',
  styleUrls: ['./hotels.component.css']
})
export class HotelsComponent implements OnInit {
  hotel:Hotel[];
  htl:Hotel=new Hotel;
  constructor(private authService: AuthService,private router:Router,private toastr:ToastrService) { }

  ngOnInit(): void {
    this.authService.loadToken();
    this.authService.parseJWT();
    this.authService.getHotels().subscribe(
      (data:any)=>{
        this.hotel =data;
      }
    );
  }
  onNewCat() {
    this.router.navigateByUrl('/addHotel');

  }
  onEditHotel(id:number){
    // console.log("iddd=",id);
    this.router.navigate(['editHotel',id]);
  }
  onDeleteHotel(id:number){
    let confirm=window.confirm("Voulez vous supprimer !!!");
    if (confirm==true){
      this.authService.deleteHotel(id).subscribe(
        (data)=>{
          this.toastr.warning("l'Hotel a été supprimer avec succès")
          setTimeout(() => {
            this.ngOnInit();
          }, 100);

        },error => {
          console.log("erreur",error);
        }
      )
    }
  }
  isAdmin(){
    return this.authService.isAdmin();
  }

}
