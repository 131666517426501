import { Component, OnInit } from '@angular/core';
import {ClientService} from '../../services/client.service';
import {Client} from '../../Models/Client';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {

  constructor(public clientService: ClientService) { }
  clients: Client[];
  mode = 'lists';

  ngOnInit(): void {
    this.clientService.getClient().subscribe(
      (data: any) => {
        this.clients = data;
      }
    );
  }
  onNewCat() {
    this.mode = 'new-client';
  }

  onLists() {
    this.mode = 'lists';
  }
}
