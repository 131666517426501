import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Hotel } from '../Models/Hotel';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-edit-hotel',
  templateUrl: './edit-hotel.component.html',
  styleUrls: ['./edit-hotel.component.css']
})
export class EditHotelComponent implements OnInit {
  idHotel: number;
  hotel: Hotel = new Hotel();
  constructor(private authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router, private toastr: ToastrService) {
    this.idHotel = activatedRoute.snapshot.params['id'];

  }

  ngOnInit(): void {

    this.authService.getOneHotel(this.idHotel).subscribe(
      (data: any) => {
        this.hotel = data;
        console.log(this.hotel);

      }, error => {
        console.log(error);

      }
    )
  }

  updateHotel() {
    this.authService.updateHotel(this.hotel).subscribe(
      (data: any) => {
        this.toastr.success("l'hotel a été modifier avec succès.");
        this.router.navigateByUrl("/hotels");
      }, error => {
        console.log("erreur de modification");
        alert("Erreur ")
      }
    )
  }


}
