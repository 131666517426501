import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Utilisateur } from '../Models/Utilisateurs';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-view-utilisateur',
  templateUrl: './view-utilisateur.component.html',
  styleUrls: ['./view-utilisateur.component.css']
})
export class ViewUtilisateurComponent implements OnInit {

  idUtil: number;
  util: Utilisateur = new Utilisateur();
  utilHotel;
  constructor(private authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router, private toastr: ToastrService) {
    this.idUtil = activatedRoute.snapshot.params['id'];

  }

  ngOnInit(): void {
    this.authService.getOneUtilisateur(this.idUtil).subscribe(
      (data: any) => {
        this.util = data;
        this.utilHotel=this.util.hotel['hotelName'];
        // console.log(this.util);

      }, error => {
        console.log(error);

      }
    )
  }
  onUtilisateurs() {
    this.router.navigateByUrl('/utilisateurs');

  }

}
