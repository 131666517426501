import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(public httpClient: HttpClient) { }
  public getClient() {
    return this.httpClient.get('http://localhost:8000/stock_management/api/v1/stock_manager/clients/');
  }
}
